export const initOnEvents = ['click', 'touchstart', 'keypress', 'keydown'];

export const getAudioContext = () => {
  let audioCtx;
  try {
    audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  } catch {
    // AudioContext is not available.
  }
  return audioCtx;
};

export const getAlertAudio = async (baseUrl = '', requestContext) => {
  window.playAudioAlerts = () => {
    const { type = 'dashboard', alertTone = 'ding' } = requestContext || {};
    // eslint-disable-next-line no-console
    const resourceUrl = `${baseUrl}/audio/${type}/${alertTone}.mp3`;
    const audio = new Audio(resourceUrl);
    audio.play();
  };
};
